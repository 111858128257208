const _thin = "100"
const _extralight = "200"
const _light = "300"
const _normal = "400"
const _medium = "500"
const _semibold = "600"
const _bold = "700"
const _extrabold = "800"
const _black = "900"
const config = { "thin": _thin, "extralight": _extralight, "light": _light, "normal": _normal, "medium": _medium, "semibold": _semibold, "bold": _bold, "extrabold": _extrabold, "black": _black, "apfel-brukt": "300", "apfel-regular": "400", "apfel-mittel": "500", "apfel-satt": "600", "apfel-fett": "700",  }
export { config as default, _thin, _extralight, _light, _normal, _medium, _semibold, _bold, _extrabold, _black }